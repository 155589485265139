export function getWarningsPerConformanceTarget(conformanceTarget, warningsPerConformanceLvl) {
    if (!warningsPerConformanceLvl) return 0;

    let errorCountForconformanceTarget = warningsPerConformanceLvl.A;

    if (conformanceTarget.includes('AAA')) {
        errorCountForconformanceTarget +=
            warningsPerConformanceLvl.AAA + warningsPerConformanceLvl.AA;
    } else if (conformanceTarget.includes('AA')) {
        errorCountForconformanceTarget += warningsPerConformanceLvl.AA;
    }

    if (conformanceTarget.includes('Best Practice')) {
        errorCountForconformanceTarget += warningsPerConformanceLvl['Best Practice'];
    }

    return errorCountForconformanceTarget;
}

export function calcAvgErrorsPerPage(conformanceTarget, data) {
    if (data !== undefined) {
        const warningsForConformanceTarget = getWarningsPerConformanceTarget(
            conformanceTarget,
            data.warningsPerConformanceLvl
        );
        return (warningsForConformanceTarget / Object.keys(data.summaryPerPage).length).toFixed(1);
    } else return '';
}

export default function calculateAccessibilityData({
    conformanceTarget,
    report,
    history,
    eaRules,
    wcagRules,
    auditEntry
}) {
    const accessibilityDataResult = { ...auditEntry };

    function getTotalwarningsForConformanceTarget(warningsPerConformanceLvl) {
        return getWarningsPerConformanceTarget(conformanceTarget, warningsPerConformanceLvl);
    }

    function calcTotalwarningsForConformanceTarget(summaryForPageOrDomain) {
        return getTotalwarningsForConformanceTarget(
            summaryForPageOrDomain?.warningsPerConformanceLvl
        );
    }

    function calcAvgErrorCount(indexOffset) {
        const dates = Object.keys(history);
        const date = dates[dates.length - indexOffset];
        const data = history[date];
        return calcAvgErrorsPerPage(conformanceTarget, data);
    }

    function calcPagesChecked(indexOffset) {
        const dates = Object.keys(history);
        const date = dates[dates.length - indexOffset];
        const data = history[date];
        if (data !== undefined) {
            return Object.keys(data.summaryPerPage).length;
        } else return 0;
    }

    /* Function to get OverallScore based on conformance Target */
    function calcOverallAuditScore(auditScores, isRounded, siteTarget) {
        if (!auditScores) return 0;
        const multi = isRounded ? 1 : 100;
        siteTarget = siteTarget || conformanceTarget || 'AAA + BP';
        const conformanceTargetParts = siteTarget.split(' + ');

        // return already calculated scores if available
        if (Object.prototype.hasOwnProperty.call(auditScores, 'TargetA')) {
            let conformanceTargetScore = `Target${conformanceTargetParts[0]}`;
            if (conformanceTargetParts[1]) conformanceTargetScore += `_BP`;
            return (auditScores[conformanceTargetScore] * multi).toFixed(2);
        }

        let overallScore = parseFloat(auditScores.A) * 2;
        let averageCount = 2;

        if (siteTarget.includes('AAA')) {
            overallScore += parseFloat(auditScores.AA) * 2 + parseFloat(auditScores.AAA);
            averageCount += 3;
        } else if (siteTarget.includes('AA')) {
            overallScore += parseFloat(auditScores.AA) * 2;
            averageCount += 2;
        }

        if (siteTarget.includes('Best Practice')) {
            overallScore += parseFloat(auditScores['Best Practice']);
            averageCount += 1;
        }

        return ((overallScore / averageCount) * multi).toFixed(1);
    }

    /* get rules based on selected conformance target */
    function getRulesForSelectedConformanceTarget(rules) {
        const rulesForSelectedConformanceTarget = {};

        for (let [rule, ruleData] of Object.entries(rules)) {
            // set the ruleID as reference
            ruleData.eyeAbleId = rule;

            // map EA-Rule to this rule
            ruleData.eaRule = eaRules[rule];

            if (!eaRules[rule]) {
                console.log('Rule not found', rule);
                console.log('EaRules', eaRules);
            } else {
                const wcagChapter = ruleData.eaRule.categories[0];
                // map WCAG-Rule to this rule
                ruleData.wcagRule = wcagRules[wcagChapter];
                const wcagLvl = ruleData.wcagRule.wcagLvl;

                if (conformanceTarget.includes(wcagLvl)) {
                    rulesForSelectedConformanceTarget[rule] = ruleData;
                }
            }
        }
        return rulesForSelectedConformanceTarget;
    }

    const auditScores = report?.summary?.scores ?? report?.summary?.auditScores ?? {};

    const rules = report?.rules;

    accessibilityDataResult.rules = rules;

    accessibilityDataResult.avgErrorCount = history
        ? [calcAvgErrorCount(1), calcAvgErrorCount(2)]
        : [];

    accessibilityDataResult.pagesChecked =
        history && report ? [calcPagesChecked(1), calcPagesChecked(2)] : [0, 0];

    accessibilityDataResult.lastTotalPagesChecked =
        Object.keys(report.summary.summaryPerPage)?.length || 0;

    accessibilityDataResult.lastAvgErrorCount =
        (
            getTotalwarningsForConformanceTarget(report.summary.warningsPerConformanceLvl) /
            accessibilityDataResult.lastTotalPagesChecked
        )?.toFixed(1) || 0;

    accessibilityDataResult.summaryDetailScore = calcOverallAuditScore(auditScores) || '0';

    const rulesForSelectedConformanceTarget = getRulesForSelectedConformanceTarget(rules);

    accessibilityDataResult.rulesForSelectedConformanceTarget = rulesForSelectedConformanceTarget;

    /* Sort the rules by the number of errors */
    const keysSorted = Object.keys(rulesForSelectedConformanceTarget).sort(
        (a, b) =>
            rulesForSelectedConformanceTarget[b]?.totalErrors -
            rulesForSelectedConformanceTarget[a]?.totalErrors
    );

    accessibilityDataResult.mostCommonErrors = [];

    // data for most common errors table
    for (let i = 0; i < 5; i++) {
        const key = keysSorted[i];
        if (
            rulesForSelectedConformanceTarget[key] &&
            rulesForSelectedConformanceTarget[key].totalErrors > 0
        ) {
            accessibilityDataResult.mostCommonErrors.push({
                key: eaRules[key].content,
                value: rulesForSelectedConformanceTarget[key]
            });
        }
    }

    const foundPages = Object.keys(report?.summary?.summaryPerPage) || [];

    accessibilityDataResult.calcOverallAuditScore = calcOverallAuditScore;
    accessibilityDataResult.calcTotalwarningsForConformanceTarget =
        calcTotalwarningsForConformanceTarget;

    accessibilityDataResult.auditScores = auditScores;
    accessibilityDataResult.history = history;
    accessibilityDataResult.report = report;
    accessibilityDataResult.eaRules = eaRules;
    accessibilityDataResult.wcagRules = wcagRules;
    accessibilityDataResult.conformanceTarget = conformanceTarget;
    accessibilityDataResult.foundPages = foundPages;

    return accessibilityDataResult;
}
