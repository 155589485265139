import theme from '@assets/theme';
import OfferContactForm from '@components/OfferContactForm';
import RouterLink from '@components/RouterLink';
import { useFetchLicenseDataAndSetStoreValues, useFetchWhoami } from '@http/queries';
import MainLayout from '@layout/MainLayout';
import {
    Alert,
    Box,
    Button,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Snackbar
} from '@mui/material';
import * as Sentry from '@sentry/browser';
// import yupTranslation from '/locales/de/yupTranslation';
import useWindowSize from '@hooks/useWindowSize.jsx';
import { ThemeProvider } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import 'chartjs-adapter-date-fns';
import { fromUnixTime } from 'date-fns';
import { useAtom } from 'jotai';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, ScrollRestoration, useLocation, useMatches } from 'react-router-dom';
import {
    cookieBannerAcceptedAtom,
    demoModeAtom,
    rerenderLayoutAtom,
    toastsAtom,
    tokenExpiresAtom,
    userAtom
} from './global-store';
import clearStorageWithExeptions from './helpers/clearStorageWithExeptions';

function checkIfRouteIsAllowed(user, matches, demoMode) {
    for (let match of matches) {
        let allowed = match.handle?.allowed;

        if (!allowed) {
            console.warn('Route Item has no allowed property', match);
            continue;
        }

        if (!Array.isArray(allowed)) {
            allowed = [allowed];
        }

        for (let allowedItem of allowed) {
            if (allowedItem === 'all') continue; // allowed for all users

            if (user) {
                if (allowedItem === 'no-user') {
                    console.warn('NO-USER-Route has user', user, match);
                    return demoMode ? '/demo' : '/';
                }

                if (allowedItem === 'admin' && !user.isAdmin) {
                    console.warn('ADMIN-Route user is not admin', user, match);
                    return demoMode ? '/demo' : '/';
                }

                if (allowedItem === 'no-demo' && demoMode) return '/demo';
            } else {
                // no user, only allowed to access 'no-user' routes
                if (allowedItem !== 'no-user') {
                    console.warn('Trying to access USER route without user', user, match);
                    return '/authentication/login';
                }
            }
        }
    }
}

function Notification({ notification, onExited, ...props }) {
    const [open, setOpen] = useState(true);

    function handleClose(_event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    if (!notification?.message) return null;

    return (
        <Snackbar
            TransitionProps={{ onExited }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={parseInt(notification.duration) || 7000}
            key={notification.key}
            onClose={handleClose}
            open={open}
            {...props}
        >
            <Alert
                severity={notification.severity}
                sx={{
                    'border': '2px solid black',
                    'fontWeight': 600,
                    'color': theme.palette.black.main,
                    'alignItems': 'center',
                    'display': 'flex',
                    'flexWrap': 'nowrap',
                    '& .MuiAlert-message': {
                        flexShrink: 1,
                        display: 'flex',
                        alignItems: 'center'
                    }
                }}
            >
                <Box aria-live='polite'>{notification.message}</Box>

                <IconButton onClick={handleClose} size='small' sx={{ ml: 2 }}>
                    <IconX />
                </IconButton>
            </Alert>
        </Snackbar>
    );
}

function BaseRoute() {
    const { i18n, t, ready } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [langSetup, setLangSetup] = useState(false);
    const [renderKey, setRenderKey] = useAtom(rerenderLayoutAtom);

    const queryClient = useQueryClient();

    const { isLoading: LicenseDataLoading, isError: LicenseDataError } =
        useFetchLicenseDataAndSetStoreValues();
    const { isLoading: whoamiLoading, isError: whoamiError } = useFetchWhoami();

    // prefetching translation and simplelangue domains
    //usePreFetchTranslationDomains();

    const [user, setUser] = useAtom(userAtom);
    const [toasts, setToasts] = useAtom(toastsAtom);
    const [tokenExpires, setTokenExpires] = useAtom(tokenExpiresAtom);
    const [demoMode] = useAtom(demoModeAtom);
    const [cookieBannerAccepted, setCookieBannerAccepted] = useAtom(cookieBannerAcceptedAtom);

    const windowsSize = useWindowSize().width;
    const isMobileOrTablet = windowsSize < 1180;
    const isMobile = windowsSize < 580;

    const matches = useMatches();

    const location = useLocation();

    const path = location.pathname.endsWith('/')
        ? location.pathname.slice(0, -1)
        : location.pathname;

    const lastMatch = matches[matches?.length - 1]?.handle;
    if (lastMatch) {
        let title = lastMatch?.i18nNameKey ? t(lastMatch.i18nNameKey) : lastMatch.name;

        // check if the item before is a collapsible item
        const secondLastMatch = matches[matches?.length - 2]?.handle;
        if (secondLastMatch?.type === 'collapse') {
            title =
                (secondLastMatch?.i18nNameKey
                    ? t(secondLastMatch.i18nNameKey)
                    : secondLastMatch.name) +
                ' - ' +
                title;
        }

        document.title = title + ' | Eye-Able® Dashboard';
    } else {
        document.title = 'Eye-Able® Dashboard';
    }

    useEffect(() => {
        if (whoamiError | LicenseDataError) {
            if (['privacy-policy', 'authentication'].includes(path.split('/')[1]))
                return setIsLoading(false);
            else {
                console.log('BaseRoute: whoamiError', whoamiError);
                Sentry.setUser(undefined);
                setUser(undefined);
                clearStorageWithExeptions();
                window.location.replace('/authentication/login');
            }
        }

        // after whoami is loaded and there is no error, there must be a user,
        // but it takes one render until the user is in the store
        if (whoamiLoading || LicenseDataLoading || !user) return;

        Sentry.setUser({
            id: user.id,
            username: user.firstName?.[0] + '.' + user.lastName?.[0]
        });
        setIsLoading(false);
    }, [whoamiLoading, whoamiError, user, setUser, LicenseDataLoading, LicenseDataError, path]);

    useEffect(() => {
        if (ready) {
            // set document language
            document.documentElement.lang = i18n.resolvedLanguage;
            setLangSetup(true);
        }
    }, [ready, i18n.resolvedLanguage, setLangSetup]);

    useEffect(() => {
        if (langSetup && !isLoading) {
            document.querySelector('#boot-screen').style.opacity = 0;

            setTimeout(() => {
                // hide loadingscreen, wait for the animation to finish
                document.body.style.overflow = 'auto';
                document.querySelector('#boot-screen').style.display = 'none';
            }, 700);
        } else {
            document.body.style.overflowX = 'hidden';
            document.body.style.overflowY = 'visible';
            document.querySelector('#boot-screen').style.display = 'flex';
        }
    }, [isLoading, demoMode, user, path, i18n.resolvedLanguage, queryClient, langSetup]);

    useEffect(() => {
        if (!tokenExpires || !user) return;
        // show an alert before the token expires
        // TODO: show a dialog with relogin option
        const now = new Date();

        const expires = Number(tokenExpires);

        const expiresInSeconds = expires - (now.getTime() / 1000).toFixed(0);

        const expiresInMs = expiresInSeconds * 1000;

        const expireDate = fromUnixTime(Number(tokenExpires));

        console.log('Token expires at', expireDate, ' | in', expiresInSeconds, 'seconds');

        // check if the token will expires in less than 24 days, which is the max value for setTimeout
        if (expiresInMs < 2147480000) {
            // logout the user when the token expires
            setTimeout(() => {
                console.log('Token expired, logging out');
                setUser(undefined);
                setTokenExpires(undefined);
                alert('Your session has expired, please login again on the following page');
                window.location.replace('/authentication/login');
            }, expiresInMs);

            // show an alert 10 minutes before the token expires
            // TODO: show a dialog with relogin option
            setTimeout(() => {
                console.log('Token will expire in 10 minutes');
                // Set the minutes to 10 minutes before the token expires
                expireDate.setMinutes(expireDate.getMinutes() - 10);

                // Get hours and minutes, adding a leading zero if necessary
                const hours = expireDate.getHours().toString().padStart(2, '0');
                const minutes = expireDate.getMinutes().toString().padStart(2, '0');

                alert(
                    `Your Session will expire in 10 minutes (at ${hours}:${minutes}), please logout and login again`
                );
            }, expiresInMs - 6000);
        }
    }, [tokenExpires, user, setUser, setTokenExpires]);

    /*     useEffect(() => {
        function disableDemoOnLogin(event) {
            if (event.key === 'demoMode' && event.newValue === 'false') {
                console.log('Login detected, disabling demo mode');
                setDemoMode(false);
                window.location.replace('/');
            }
        }

        if (demoMode) {
            window.addEventListener('storage', disableDemoOnLogin);
        }

        return () => {
            window.removeEventListener('storage', disableDemoOnLogin);
        };
    }, [demoMode, setDemoMode]); */

    // Reload page if localstorage changes from another tab
    useEffect(() => {
        function reloadPage(event) {
            if (!document.hidden) return; // check if the tab is hidden

            const { key, oldValue, newValue } = event;

            if (
                [
                    'reload',
                    'user',
                    'demoMode',
                    'X-Dev-Token',
                    'currentAuditEntryId',
                    'i18nextLng'
                ].includes(key) &&
                newValue !== oldValue
            ) {
                console.log('storage changed, reloading page', key, newValue);
                window.location.reload();
            }
        }

        // this event only fires when the localstorage is changed from another tab
        window.addEventListener('storage', reloadPage);

        return () => {
            window.removeEventListener('storage', reloadPage);
        };
    }, [setRenderKey]);

    useEffect(() => {
        // add padding so that the footer is not covered by the contact buttons
        document.getElementById('app').style.paddingBottom =
            isMobileOrTablet && demoMode ? (isMobile ? '64px' : '32px') : 0;
    }, [isMobileOrTablet, demoMode, isMobile]);

    if (path === '/report') return <Navigate to='/report/overview' />;

    if (!langSetup || isLoading) {
        return <></>;
    }

    if (
        user &&
        !user.hasPassword &&
        path !== '/authentication/change-password' &&
        !demoMode &&
        !window.location.origin.includes('admin')
    ) {
        console.log('User has no password, redirecting to change password', path);
        return window.location.assign('/authentication/change-password');
    } else {
        const redirectPath = checkIfRouteIsAllowed(user, matches, demoMode);

        if (redirectPath) {
            if (path === redirectPath) return console.log('baseRoute | already on the right page');
            console.info('baseRoute | redirecting to', redirectPath);
            return window.location.assign(redirectPath);
        }
    }

    return (
        <>
            <ThemeProvider theme={theme} key={i18n.resolvedLanguage}>
                <CssBaseline />

                <ScrollRestoration />

                <Fragment key={renderKey}>
                    {!user || path.startsWith('/authentication/') ? <Outlet /> : <MainLayout />}
                </Fragment>

                {cookieBannerAccepted || path.includes('privacy-policy') ? null : (
                    <Dialog
                        aria-label='Cookie Banner'
                        aria-labelledby='#cookieText'
                        maxWidth='xs'
                        open
                        role='dialog'
                        sx={{
                            '& .MuiDialog-paper': { width: '80%', maxHeight: 435 },
                            'zIndex': 9999
                        }}
                    >
                        <DialogContent dividers>
                            <DialogContentText
                                id='cookieText'
                                sx={{ fontWeight: 500, color: '#333' }}
                            >
                                {t('cookieBannerText')}
                                <br />
                                <br />
                                {t('cookieBannerMore')}
                                <RouterLink
                                    color='#0000ff'
                                    target='_blank'
                                    to='/privacy-policy'
                                    underline='always'
                                >
                                    {t('privacyPolicy')}
                                </RouterLink>
                                .
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={() => setCookieBannerAccepted(true)}
                                variant='outlined'
                            >
                                {t('close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}

                {toasts.map(toast => (
                    <Notification
                        key={toast.key}
                        notification={toast}
                        onExited={() =>
                            setToasts(toasts => toasts.filter(t => t.key !== toast.key))
                        }
                    />
                ))}

                {demoMode ? (
                    <Box
                        sx={{
                            'position': 'fixed',
                            'bottom': 0,
                            'left': 0,
                            'zIndex': 1150,
                            'width': '100%',

                            '& .MuiButton-root': {
                                width: '100%',
                                borderRadius: 0
                            },

                            '@media (min-width:1190px)': {
                                'left': '50%',
                                'transform': 'translateX(-50%)',
                                'width': 'auto',

                                '& .MuiButton-root': {
                                    width: 'auto !important',
                                    borderRadius: '50px !important'
                                },

                                'top': '16.5px',
                                'bottom': 'unset'
                            },

                            '@media (max-width:1189px)': {
                                '& .MuiButton-root, & #offer-contact-button': {
                                    backgroundColor: theme.palette.secondary.main,
                                    color: theme.palette.black.main,
                                    width: '50%'
                                },
                                '& .MuiButton-root': {
                                    borderRight: '0.75px solid #000'
                                },
                                '& #offer-contact-button .MuiButton-root': {
                                    width: '100%',
                                    borderLeft: '0.75px solid #000',
                                    borderRight: 'none'
                                }
                            },

                            '@media (max-width:579px)': {
                                '& .MuiButton-root, & #offer-contact-button': {
                                    width: '100%'
                                }
                            }
                        }}
                    >
                        <OfferContactForm
                            buttonColor='secondary'
                            fullWidthMobile
                            size='small'
                            showBookConsultation
                        />
                    </Box>
                ) : null}
            </ThemeProvider>
        </>
    );
}

//export { yup }
export default BaseRoute;
