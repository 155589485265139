import { Box, Chip, Tooltip } from '@mui/material';
import { forwardRef } from 'react';

const HeaderChip = forwardRef(function HeaderChipRef(
    { onClick, imgSrc, Icon, id, tooltip, text, sx, disabled = false },
    ref
) {
    return (
        <Tooltip title={tooltip}>
            <Chip
                aria-controls={undefined}
                aria-haspopup='true'
                aria-label={tooltip}
                color='primary'
                component='button'
                disabled={disabled}
                id={id}
                label={
                    <>
                        {Icon ? (
                            <Icon size='1.38rem' />
                        ) : (
                            <img alt='' aria-hidden='true' src={imgSrc} style={{ width: '30px' }} />
                        )}

                        {text ? (
                            <Box
                                sx={{
                                    letterSpacing: '0.4px',
                                    pl: 0.5,
                                    textTransform: 'uppercase'
                                }}
                            >
                                {text}
                            </Box>
                        ) : null}
                    </>
                }
                onClick={onClick}
                ref={ref}
                sx={theme => ({
                    'border': `2px solid ${theme.palette.primary.main}`,
                    'borderRadius': theme.borders.borderRadius.xl,
                    'alignItems': 'center',
                    'transition': 'all .2s ease-in-out',
                    'backgroundColor': theme.palette.primary.white,
                    'cursor': 'pointer',

                    '& svg': {
                        stroke: theme.palette.primary.main
                    },

                    '&:hover, &:focus': {
                        'background': `${theme.palette.primary.main} !important`,
                        'color': theme.palette.primary.white,

                        '& svg': {
                            stroke: theme.palette.secondary.main
                        }
                    },

                    '& .MuiChip-label': {
                        lineHeight: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    },

                    ...sx
                })}
                variant='outlined'
            />
        </Tooltip>
    );
});

export default HeaderChip;
