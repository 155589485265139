import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconDeviceFloppy, IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * DynamicDialog is a component for displaying a dialog with various customizable properties.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} [props.title='Dialog Title'] - The title of the dialog.
 * @param {boolean} [props.hideActivator=false] - If true, the activator button is hidden.
 * @param {boolean} [props.overwriteOpen] - Pass a boolean to overwrite the open state of the dialog.
 * @param {string} [props.activatorText='Open Dialog'] - The text for the dialog activator.
 * @param {Object} [props.activatorProps={}] - Additional properties for the activator.
 * @param {string} [props.activatorType='button'] - The type of the activator. Can be 'button' or 'text' or 'icon'
 * @param {string} [props.toolTipText=''] - The tooltip text for the activator.
 * @param {Function} [props.onOpen] - Callback function when the dialog is opened. Must return true to open the dialog.
 * @param {Function} [props.onCancel] - Callback function when the dialog is canceled.
 * @param {Function} [props.onConfirm] - Callback function when the dialog is confirmed.
 * @param {boolean} [props.disableConfirm=false] - If true, the save button is disabled.
 * @param {boolean} [props.fullWidth=false] - If true, the dialog takes the full width of the screen.
 * @param {string} [props.maxWidth='lg'] - The maximum width of the dialog.
 * @param {boolean} [props.alwaysFullscreen=false] - If true, the dialog is always in fullscreen mode.
 * @param {boolean} [props.neverFullscreen=false] - If true, the dialog never goes into fullscreen mode.
 * @param {boolean} [props.hideActions=false] - If true, the action buttons are hidden.
 * @param {string} [props.confirmBtnText] - The text for the submit button.
 * @param {ReactNode} [props.confirmBtnIcon] - The icon for the submit button.
 * @param {Object} [props.confirmBtnProps={}] - Additional properties for the submit button.
 * @param {string} [props.id] - The ID of the component.
 * @param {Object} [props.contentSx={}] - Additional styles for the content.
 * @param {ReactNode} [props.dialogActionsContent] - Additional content for the dialog actions.
 * @param {ReactNode | Function} [props.children] - The children nodes of the component.
 */
function DynamicDialog({
    title = 'Dialog Title',
    hideActivator = false,
    overwriteOpen,
    activatorText = 'Open Dialog',
    activatorProps = {},
    activatorType = 'button',
    toolTipText = '',
    onOpen,
    onCancel,
    onConfirm,
    disableConfirm = false,
    fullWidth = false,
    maxWidth = 'lg',
    alwaysFullscreen = false,
    neverFullscreen = false,
    hideActions = false,
    hideConfirmBtn = false,
    confirmBtnText,
    confirmBtnIcon,
    confirmBtnProps = {},
    cancelBtnText,
    id,
    contentSx = {},
    dialogActionsContent = null,
    children,
    ...rest
}) {
    const theme = useTheme();

    const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

    let fullScreen = alwaysFullscreen || smAndDown;
    if (neverFullscreen) fullScreen = false;

    const [open, setOpen] = useState(overwriteOpen ? true : false);
    const [saving, setSaving] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        setOpen(overwriteOpen ? true : false);
    }, [overwriteOpen]);

    async function handleOpen() {
        if (!!overwriteOpen && onOpen) {
            return await onOpen();
        }
        if (!onOpen) setOpen(true);
        else {
            const shouldOpen = await onOpen();
            if (shouldOpen) setOpen(true);
        }
    }

    async function handleCancel() {
        if (saving) return;
        if (onCancel) {
            setSaving(true);
            const shouldClose = await onCancel();
            setSaving(false);
            if (shouldClose) setOpen(false);
        } else {
            setOpen(false);
        }
    }

    async function handleSave(e) {
        e?.preventDefault();

        if (!onConfirm) setOpen(false);
        else {
            setSaving(true);
            const shouldClose = await onConfirm();
            setSaving(false);
            if (shouldClose) setOpen(false);
        }
    }

    return (
        <>
            {hideActivator ? null : (
                <Tooltip title={toolTipText} describeChild={activatorType !== 'icon'}>
                    <span id={id}>
                        {activatorType === 'icon' ? (
                            <IconButton onClick={handleOpen} {...activatorProps}>
                                {activatorText}
                            </IconButton>
                        ) : null}

                        {activatorType === 'button' ? (
                            <Button onClick={handleOpen} variant='outlined' {...activatorProps}>
                                {activatorText}
                            </Button>
                        ) : null}

                        {activatorType === 'text' ? (
                            <Box
                                component='span'
                                onClick={handleOpen}
                                {...activatorProps}
                                sx={{ cursor: 'pointer', ...activatorProps?.sx }}
                            >
                                {activatorText}
                            </Box>
                        ) : null}
                    </span>
                </Tooltip>
            )}
            <Dialog
                aria-describedby='alert-dialog-description'
                aria-labelledby='alert-dialog-title'
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={handleCancel}
                open={open}
                scroll='paper'
                {...rest}
            >
                <DialogTitle
                    sx={[
                        {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            color: '#000',
                            minHeight: '64px',
                            py: 1.5,
                            px: 3,
                            letterSpacing: '0.2px',
                            gap: 2.5
                        },
                        fullScreen
                            ? {
                                  background: '#f3f3f3'
                              }
                            : {
                                  background: 'white'
                              }
                    ]}
                >
                    <Box>{title}</Box>

                    <IconButton color='black' onClick={handleCancel} title={t('close')}>
                        <IconX strokeWidth={3} />
                    </IconButton>
                </DialogTitle>

                <DialogContent
                    dividers
                    sx={[
                        {
                            fontSize: '1em',
                            ...contentSx
                        },
                        neverFullscreen
                            ? {
                                  minWidth: '250px'
                              }
                            : {
                                  minWidth: fullScreen ? 'auto' : '510px !important'
                              }
                    ]}
                >
                    {typeof children === 'function'
                        ? children({ handleCancel, handleSave })
                        : children}
                </DialogContent>

                {hideActions ? (
                    <></>
                ) : (
                    <DialogActions
                        sx={[
                            {
                                display: 'flex',
                                alignItems: 'center',
                                color: '#000',
                                minHeight: '64px',
                                py: 0,
                                px: 2.5
                            },
                            fullScreen
                                ? {
                                      background: '#f3f3f3'
                                  }
                                : {
                                      background: 'white'
                                  }
                        ]}
                    >
                        {dialogActionsContent ? dialogActionsContent : null}
                        <Button
                            color='black'
                            disabled={saving}
                            onClick={handleCancel}
                            variant='text'
                        >
                            {cancelBtnText || t('cancel')}
                        </Button>

                        {hideConfirmBtn ? null : (
                            <LoadingButton
                                variant='text'
                                onClick={handleSave}
                                color='primary'
                                loading={saving}
                                startIcon={confirmBtnIcon || <IconDeviceFloppy />}
                                disabled={disableConfirm}
                                {...confirmBtnProps}
                            >
                                {confirmBtnText || t('save')}
                            </LoadingButton>
                        )}
                    </DialogActions>
                )}
            </Dialog>
        </>
    );
}

DynamicDialog.propTypes = {
    activatorProps: PropTypes.object,
    activatorText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    activatorType: PropTypes.oneOf(['button', 'icon', 'text']),
    alwaysFullscreen: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    contentSx: PropTypes.object,
    disableConfirm: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hideActions: PropTypes.bool,
    hideActivator: PropTypes.bool,
    iconButton: PropTypes.element,
    id: PropTypes.string,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
    neverFullscreen: PropTypes.bool,
    onCancel: PropTypes.func,
    onOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    overwriteOpen: PropTypes.bool,
    confirmBtnProps: PropTypes.object,
    confirmBtnIcon: PropTypes.element,
    confirmBtnText: PropTypes.string,
    title: PropTypes.string,
    toolTipText: PropTypes.string
};

export default DynamicDialog;
