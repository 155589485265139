import { Box, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFetchJiraUsersProjectAssignable } from '@http/queries';
import { useMemo, forwardRef, useImperativeHandle, useRef } from 'react';
import LoadingSpinner from '@/components/LoadingSpinner';
import IssueExportForm from './IssueExportForm';

export const JiraIssueExport = forwardRef(function JiraIssueExportRef(
    { todo, jiraProject, linkedJiraUserID },
    ref
) {
    const { data: jiraUsers } = useFetchJiraUsersProjectAssignable(jiraProject.key);
    const issueExportFormRef = useRef();

    useImperativeHandle(ref, () => ({
        async handlePost() {
            if (!issueExportFormRef.current.handlePost) return false;
            const response = await issueExportFormRef.current.handlePost();
            return response;
        }
    }));

    if (!jiraUsers) return <LoadingSpinner />;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <IssueExportForm
                ref={issueExportFormRef}
                todosToExport={[todo]}
                jiraProject={jiraProject}
                jiraUsers={jiraUsers}
                linkedJiraUserID={linkedJiraUserID}
            />
        </Box>
    );
});

export const JiraIssueExportAll = forwardRef(function JiraIssueExportAllRef(
    { jiraProject, linkedJiraUserID, boardData },
    ref
) {
    const { t } = useTranslation();
    const issueExportFormRef = useRef();

    const { data: jiraUsers } = useFetchJiraUsersProjectAssignable(jiraProject.key);

    const todosToExport = useMemo(() => {
        return Object.values(boardData).filter(_todo => {
            return !_todo?.jira?.id && !_todo.resolved;
        });
    }, [boardData]);

    useImperativeHandle(ref, () => ({
        async handlePost() {
            if (!issueExportFormRef.current.handlePost) return false;
            const response = await issueExportFormRef.current.handlePost();
            return response;
        }
    }));

    if (!jiraUsers) return <LoadingSpinner />;

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Box>{t('todosTobeExported')}</Box>
                <Chip
                    sx={{ fontWeight: 'bold', fontSize: '1rem', ml: 1 }}
                    label={todosToExport.length}
                    color='info'
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <IssueExportForm
                    ref={issueExportFormRef}
                    todosToExport={todosToExport}
                    jiraProject={jiraProject}
                    jiraUsers={jiraUsers}
                    linkedJiraUserID={linkedJiraUserID}
                />
            </Box>
        </>
    );
});
