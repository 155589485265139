export function JiraIcon({ variant = 'default', size = 24, grayscale = false, ...restProps }) {
    return variant === 'default' ? (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            // eslint-disable-next-line react/no-unknown-property
            xmlnsXlink='http://www.w3.org/1999/xlink'
            height={size}
            style={{ padding: '1.25px' }}
            viewBox='0 0 64 64'
            width={size}
            {...restProps}
        >
            <defs>
                <linearGradient id='A' gradientUnits='userSpaceOnUse'>
                    <stop offset='.18' stopColor='#0052cc' />
                    <stop offset='1' stopColor='#2684ff' />
                </linearGradient>
                <linearGradient
                    id='B'
                    x1='42.023'
                    y1='35.232'
                    x2='44.133'
                    y2='33.122'
                    xlinkHref='#A'
                />
                <linearGradient
                    xlinkHref='#A'
                    id='C'
                    x1='41.464'
                    y1='29.159'
                    x2='39.35'
                    y2='31.273'
                />
            </defs>
            <g transform='matrix(6.249587 0 0 6.249587 -228.82126 -169.26286)'>
                <path
                    d='M46.568 31.918l-4.834-4.834-4.834 4.834a.406.406 0 0 0 0 .573l4.834 4.834 4.834-4.834a.406.406 0 0 0 0-.573zm-4.834 1.8l-1.514-1.514 1.514-1.514 1.514 1.514z'
                    fill={grayscale ? '#757575' : '#2684ff'}
                />
                <path
                    d='M41.734 30.7a2.549 2.549 0 0 1-.011-3.594L38.4 30.408l1.803 1.803z'
                    fill={grayscale ? '#333' : 'url(#C)'}
                />
                <path
                    d='M43.252 32.2l-1.518 1.518a2.549 2.549 0 0 1 0 3.606l3.32-3.32z'
                    fill={grayscale ? '#333' : 'url(#B)'}
                />
            </g>
        </svg>
    ) : (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            // eslint-disable-next-line react/no-unknown-property
            xmlnsXlink='http://www.w3.org/1999/xlink'
            height={size}
            style={{ padding: '1.25px' }}
            viewBox='0 -30.632388516510233 255.324 285.95638851651023'
            width={size}
        >
            <linearGradient id='a'>
                <stop offset='.18' stopColor='#0052cc' />
                <stop offset='1' stopColor='#2684ff' />
            </linearGradient>
            <linearGradient
                id='b'
                x1='98.031%'
                x2='58.888%'
                xlinkHref='#a'
                y1='.161%'
                y2='40.766%'
            />
            <linearGradient
                id='c'
                x1='100.665%'
                x2='55.402%'
                xlinkHref='#a'
                y1='.455%'
                y2='44.727%'
            />
            <path
                d='M244.658 0H121.707a55.502 55.502 0 0 0 55.502 55.502h22.649V77.37c.02 30.625 24.841 55.447 55.466 55.467V10.666C255.324 4.777 250.55 0 244.658 0z'
                fill={grayscale ? '#757575' : '#2684ff'}
            />
            <path
                d='M183.822 61.262H60.872c.019 30.625 24.84 55.447 55.466 55.467h22.649v21.938c.039 30.625 24.877 55.43 55.502 55.43V71.93c0-5.891-4.776-10.667-10.667-10.667z'
                fill={grayscale ? '#333' : 'url(#b)'}
            />
            <path
                d='M122.951 122.489H0c0 30.653 24.85 55.502 55.502 55.502h22.72v21.867c.02 30.597 24.798 55.408 55.396 55.466V133.156c0-5.891-4.776-10.667-10.667-10.667z'
                fill={grayscale ? '#333' : 'url(#c)'}
            />
        </svg>
    );
}
