import RouterLink from '@/components/RouterLink.jsx';

const link = {
    defaultProps: {
        component: RouterLink,
        underline: 'none',
        color: 'inherit',
        fontSize: 'inherit'
    }
};

export default link;
