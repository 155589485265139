export function TranscriptionIcon(props) {
    return (
        <svg
            className='transcription-svg-icon'
            id='transcription-icon'
            viewBox='0 0 48 48'
            xmlns='http://www.w3.org/2000/svg'
            width='24px'
            height='24px'
            {...props}
        >
            <defs>
                <style>
                    {`
                        #transcription-icon .a {
                            fill: none;
                            stroke: currentColor;
                            stroke: currentColor;
                            stroke-width: 2.5;
                            stroke-linecap:round;
                            stroke-linejoin:round;
                        }
                    `}
                </style>
            </defs>
            <path
                className='a'
                d='M32.7554,34.8415H8.1671A2.7945,2.7945,0,0,1,5.3727,32.047V8.2944A2.7944,2.7944,0,0,1,8.1671,5.5H22.531Z'
            />
            <path
                className='a'
                d='M25.2,13.1585H39.8329a2.7945,2.7945,0,0,1,2.7944,2.7945V39.7056A2.7944,2.7944,0,0,1,39.8329,42.5H25.469L22.8,34.8414'
            />
            <line className='a' x1='32.7554' y1='34.8415' x2='25.469' y2='42.5' />
            <path
                className='a'
                d='M16.0441,11.0706h0a3.96,3.96,0,0,1,3.96,3.96v4.8958a3.96,3.96,0,0,1-3.96,3.96h0a3.96,3.96,0,0,1-3.96-3.96h0V15.0307a3.96,3.96,0,0,1,3.96-3.96Z'
            />
            <path className='a' d='M9.4018,21.1048a6.7645,6.7645,0,0,0,13.2847,0' />
            <line className='a' x1='16.0441' y1='26.5891' x2='16.0441' y2='29.9251' />
            <line className='a' x1='27.9687' y1='21.1048' x2='39.2192' y2='21.1048' />
            <line className='a' x1='31.3835' y1='30.9044' x2='39.2192' y2='30.9044' />
            <line className='a' x1='29.7307' y1='26.0046' x2='39.2192' y2='26.0046' />
        </svg>
    );
}
