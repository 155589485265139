import * as Sentry from '@sentry/react';
import { useAtom } from 'jotai';
import { object } from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import { userAtom } from './global-store.jsx';

export default function ErrorPage() {
    let error = useRouteError();

    const { t } = useTranslation();

    const [user] = useAtom(userAtom);

    useEffect(() => {
        console.info('Reporting error to Sentry:', error);

        Sentry.captureException(error);
    }, [error]);

    console.error(error);

    let msg = error?.statusText || error?.message || error;

    console.info('Error msg:', msg);

    if (typeof msg === object) {
        // error is an object but has no statusText or message
        try {
            const errorString = JSON.stringify(error);
            console.info('Error string:', errorString);
            msg = errorString;
        } catch (e) {
            // ignore
        }
    }

    msg = msg || 'Unknown error';

    function sendAdditionalInformation() {
        if (!document.getElementById('additionalInfo').value) {
            alert(t('additionalInformationMissing'));
            return;
        }
        // Send additional information to Sentry
        const userFeedback = {
            name: user?.fullName || 'No name',
            email: user?.email || 'No email',
            message: document.getElementById('additionalInfo').value,
            associatedEventId: Sentry.lastEventId() || 'User feedback'
        };
        Sentry.captureFeedback(userFeedback);
    }

    if (msg?.includes?.('Failed to fetch dynamically imported module')) {
        return (
            <>
                <h1
                    style={{
                        fontSize: '2.5rem',
                        color: 'white',
                        padding: '3rem'
                    }}
                >
                    {t('versionOutdated')}
                </h1>
            </>
        );
    }

    return (
        <>
            <h1
                style={{
                    fontSize: '2rem',
                    color: 'white',
                    padding: '3rem'
                }}
            >
                {t('unexpectedError')}:
            </h1>

            <p
                style={{
                    fontSize: '1.5rem',
                    color: 'white',
                    padding: '3rem',
                    paddingTop: 0
                }}
            >
                <i>{msg}</i>
            </p>

            <div
                style={{
                    padding: '3rem',
                    paddingTop: '1.5rem',
                    fontSize: '1.5rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    color: 'white'
                }}
            >
                <p>{t('provideAdditionalErrorInformation')}</p>
                <textarea
                    name='additionalInfo'
                    id='additionalInfo'
                    rows='5'
                    aria-label={t('additionalInformation')}
                    placeholder={t('additionalInformation')}
                ></textarea>
                <button
                    onClick={() => sendAdditionalInformation()}
                    style={{
                        padding: '10px 14px'
                    }}
                >
                    {t('send')}
                </button>
            </div>
        </>
    );
}
