import { Box, Avatar, Tooltip } from '@mui/material';
import { IconCheck, IconExclamationCircleFilled, IconUser, IconX } from '@tabler/icons-react';
import { t } from 'i18next';

export default function JiraUserInfo({ user, sx = {}, hideActiveInfo = true }) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                overflow: 'hidden',
                ...sx
            }}
        >
            {hideActiveInfo ? null : Object.prototype.hasOwnProperty.call(user, 'active') ? (
                <Tooltip title={user.active ? t('activeUser') : t('inactiveUser')}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {user.active ? (
                            <IconCheck color='green' size={24} />
                        ) : (
                            <IconExclamationCircleFilled color='#996d00' size={24} />
                        )}
                    </Box>
                </Tooltip>
            ) : (
                <Tooltip title={t('unassigned')}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <IconX color='#d10000' size={24} />
                    </Box>
                </Tooltip>
            )}
            {user?.avatarUrls ? (
                <Avatar
                    alt={`JIRA-USER-${user?.displayName}`}
                    sx={{ width: 32, height: 32 }}
                    src={user?.avatarUrls['32x32']}
                />
            ) : (
                <Avatar sx={{ width: 32, height: 32 }}>
                    <IconUser />
                </Avatar>
            )}

            <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {user?.displayName}
            </Box>
        </Box>
    );
}
