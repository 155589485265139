import { CriticalIcon, WarningIcon } from '@/components/customIcons';
import {
    IconAdjustmentsAlt,
    IconArrowsDiff,
    IconCheck,
    IconChecklist,
    IconLanguage,
    IconLoader2,
    IconQuotes,
    IconTextSpellcheck,
    IconVocabulary,
    IconWorldQuestion,
    IconX
} from '@tabler/icons-react';
import { intlFormatDistanceWithOptions } from 'date-fns/fp';

export const sidebarWidth = 350;

export const mistakeSeverity = [
    { text: 'hint', color: '#003cbd' },
    { text: 'warning', color: '#ff6600' },
    { text: 'critical', color: '#c20000' }
];

export const BookmarkCategories = {
    ISSUE: 'issue',
    BROKEN_LINKS: 'broken_links',
    PDFS_FOUND: 'pdfs_found',
    PDFS_UPLOAD: 'pdfs_upload',
    SITE: 'site',
    SITE_ISSUE: 'site_issue',
    TOP_ISSUE: 'top_issue',
    SPELLCHECKER_MISTAKE: 'spellchecker_mistake',
    SPELLCHECKER_PAGE: 'spellchecker_page'
};

export const httpStatusCodes = {
    100: 'Continue',
    101: 'Switching Protocols',
    102: 'Processing',
    200: 'OK',
    201: 'Created',
    202: 'Accepted',
    203: 'Non-Authoritative Info',
    204: 'No Content',
    205: 'Reset Content',
    206: 'Partial Content',
    300: 'Multiple Choices',
    301: 'Moved Permanently',
    302: 'Found',
    303: 'See Other',
    304: 'Not Modified',
    305: 'Use Proxy',
    307: 'Temporary Redirect',
    400: 'Bad Request',
    401: 'Unauthorized',
    402: 'Payment Required',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not Acceptable',
    407: 'Proxy Auth Required',
    408: 'Request Timeout',
    409: 'Conflict',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Failed',
    413: 'Payload Too Large',
    414: 'URI Too Long',
    415: 'Unsupported Media Type',
    416: 'Range Not Satisfiable',
    417: 'Expectation Failed',
    418: "I'm a teapot",
    422: 'Unprocessable Entity',
    425: 'Too Early',
    426: 'Upgrade Required',
    428: 'Precondition Required',
    429: 'Too Many Requests',
    431: 'Request Header Fields Too Large',
    451: 'Unavailable For Legal Reasons',
    500: 'Internal Server Error',
    501: 'Not Implemented',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    505: 'HTTP Version Not Supported',
    511: 'Network Authentication Required',
    999: 'Request Denied'
};

export const spellCheckerCategories = {
    de: {
        0: 'Stil',
        1: 'Rechtschreibung',
        2: 'Bedeutung',
        3: 'Grammatik',
        4: 'Zeichensetzung',
        5: 'Sprache',
        6: 'Sonstiges'
    },
    en: {
        0: 'Style',
        1: 'Spelling',
        2: 'Meaning',
        3: 'Grammar',
        4: 'Punctuation',
        5: 'Language',
        6: 'Other'
    },
    pt: {
        0: 'Estilo',
        1: 'Ortografia',
        2: 'Significado',
        3: 'Gramática',
        4: 'Pontuação',
        5: 'Idioma',
        6: 'Outro'
    },
    pl: {
        0: 'Styl',
        1: 'Pisownia',
        2: 'Znaczenie',
        3: 'Gramatyka',
        4: 'Interpunkcja',
        5: 'Język',
        6: 'Inne'
    },
    nl: {
        0: 'Stijl',
        1: 'Spelling',
        2: 'Betekenis',
        3: 'Grammatica',
        4: 'Interpunctie',
        5: 'Taal',
        6: 'Overig'
    },
    it: {
        0: 'Stile',
        1: 'Ortografia',
        2: 'Significato',
        3: 'Grammatica',
        4: 'Punteggiatura',
        5: 'Lingua',
        6: 'Altro'
    },
    es: {
        0: 'Estilo',
        1: 'Ortografía',
        2: 'Significado',
        3: 'Gramática',
        4: 'Puntuación',
        5: 'Idioma',
        6: 'Otro'
    },
    fr: {
        0: 'Style',
        1: 'Orthographe',
        2: 'Signification',
        3: 'Grammaire',
        4: 'Ponctuation',
        5: 'Langue',
        6: 'Autre'
    }
};

export const defaultReportSettings = {
    enableQueryParameters: false,
    fixedUserAgent: false,
    preventRedirectCheck: false,
    targetLang: '',
    urlsToCheck: [],
    regex: [],
    actions: [],
    dictionary: {},
    lighthousePreferredUrls: [],
    lighthouseExcludedUrls: [],
    // ADMIN
    maxConcurrency: '',
    colorScheme: '',
    brokenLinksRequestDelay: ''
};

export const avatarColors = {
    1: '#ff991f',
    0: '#0052cc',
    me: '#19bb00'
};

export const boardColumns = {
    backlog: {
        titleKey: 'backlog',
        includeResolved: false
    },
    working: {
        titleKey: 'working',
        includeResolved: false
    },
    finished: {
        titleKey: 'resolved',
        includeResolved: true
    }
};

export const spellCheckerCategoryIcons = {
    0: IconAdjustmentsAlt,
    1: IconTextSpellcheck,
    2: IconArrowsDiff,
    3: IconVocabulary,
    4: IconQuotes,
    5: IconLanguage,
    6: IconWorldQuestion
};

const seriousAndError = {
    icon: IconX,
    props: {
        color: '#ff5e5f'
    }
};

const infoAndHint = {
    icon: intlFormatDistanceWithOptions,
    props: {
        color: '#41c7ff'
    }
};

export const RulesStatusIcons = {
    critical: {
        icon: CriticalIcon,
        props: {
            color: '#ffffff'
        }
    },
    serious: seriousAndError,
    error: seriousAndError,
    hint: infoAndHint,
    warning: {
        icon: WarningIcon,
        props: {
            color: '#fff000'
        }
    },
    passed: {
        icon: IconCheck,
        props: {
            color: '#15ff15'
        }
    },
    info: infoAndHint,
    checksrunning: {
        icon: IconLoader2,
        props: {
            color: '#0fb0ea',
            stroke: 2.1
        }
    },
    checkscompleted: {
        icon: IconChecklist,
        props: {
            color: '#15ff15',
            stroke: 2.1
        }
    }
};

export const defaultMyGoalsBoardFilters = {
    assignee: [],
    category: [],
    showOnlyMyIssues: false,
    searchText: ''
};

export const langCodesMap = {
    ab: { name: 'Abkhaz', nativeName: 'Aҧсуа' },
    aa: { name: 'Afar', nativeName: 'Afaraf' },
    af: { name: 'Afrikaans', nativeName: 'Afrikaans' },
    ak: { name: 'Akan', nativeName: 'Akan' },
    sq: { name: 'Albanian', nativeName: 'Shqip' },
    am: { name: 'Amharic', nativeName: 'አማርኛ' },
    ar: { name: 'Arabic', nativeName: 'العربية' },
    an: { name: 'Aragonese', nativeName: 'Aragonés' },
    hy: { name: 'Armenian', nativeName: 'Հայերեն' },
    as: { name: 'Assamese', nativeName: 'অসমীয়া' },
    av: { name: 'Avaric', nativeName: 'Aвар мацӀ' },
    ae: { name: 'Avestan', nativeName: 'Avesta' },
    ay: { name: 'Aymara', nativeName: 'Aymar aru' },
    az: { name: 'Azerbaijani', nativeName: 'Azərbaycan' },
    bm: { name: 'Bambara', nativeName: 'Bamanankan' },
    ba: { name: 'Bashkir', nativeName: 'башҡорт теле' },
    eu: { name: 'Basque', nativeName: 'Euskara' },
    be: { name: 'Belarusian', nativeName: 'Беларуская' },
    bn: { name: 'Bengali', nativeName: 'বাংলা' },
    bh: { name: 'Bihari', nativeName: 'भोजपुरी' },
    bi: { name: 'Bislama', nativeName: 'Bislama' },
    bs: { name: 'Bosnian', nativeName: 'Bosanski jezik' },
    br: { name: 'Breton', nativeName: 'Brezhoneg' },
    bg: { name: 'Bulgarian', nativeName: 'български език' },
    my: { name: 'Burmese', nativeName: 'ဗမာစာ' },
    ca: { name: 'Catalan; Valencian', nativeName: 'Català' },
    ch: { name: 'Chamorro', nativeName: 'Chamoru' },
    ce: { name: 'Chechen', nativeName: 'нохчийн мотт' },
    ny: { name: 'Chichewa; Chewa; Nyanja', nativeName: 'ChiCheŵa' },
    zh: { name: 'Chinese', nativeName: '中文' },
    cv: { name: 'Chuvash', nativeName: 'чӑваш чӗлхи' },
    kw: { name: 'Cornish', nativeName: 'Kernewek' },
    co: { name: 'Corsican', nativeName: 'corsu' },
    cr: { name: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ' },
    hr: { name: 'Croatian', nativeName: 'hrvatski' },
    cs: { name: 'Czech', nativeName: 'česky, čeština' },
    da: { name: 'Danish', nativeName: 'dansk' },
    dv: { name: 'Divehi; Dhivehi; Maldivian;', nativeName: 'ދިވެހި' },
    nl: { name: 'Dutch', nativeName: 'Nederlands' },
    en: { name: 'English', nativeName: 'English' },
    eo: { name: 'Esperanto', nativeName: 'Esperanto' },
    et: { name: 'Estonian', nativeName: 'Eesti' },
    ee: { name: 'Ewe', nativeName: 'Eʋegbe' },
    fo: { name: 'Faroese', nativeName: 'føroyskt' },
    fj: { name: 'Fijian', nativeName: 'Vosa Vakaviti' },
    fi: { name: 'Finnish', nativeName: 'Suomi' },
    fr: { name: 'French', nativeName: 'Français' },
    ff: { name: 'Fula; Fulah; Pulaar; Pular', nativeName: 'Fulfulde' },
    gl: { name: 'Galician', nativeName: 'Galego' },
    ka: { name: 'Georgian', nativeName: 'ქართული' },
    de: { name: 'German', nativeName: 'Deutsch' },
    el: { name: 'Greek, Modern', nativeName: 'Ελληνικά' },
    gn: { name: 'Guaraní', nativeName: 'Avañeẽ' },
    gu: { name: 'Gujarati', nativeName: 'ગુજરાતી' },
    ht: { name: 'Haitian; Haitian Creole', nativeName: 'Kreyòl ayisyen' },
    ha: { name: 'Hausa', nativeName: 'Hausa, هَوُسَ' },
    he: { name: 'Hebrew (modern)', nativeName: 'עברית' },
    hz: { name: 'Herero', nativeName: 'Otjiherero' },
    hi: { name: 'Hindi', nativeName: 'हिन्दी, हिंदी' },
    ho: { name: 'Hiri Motu', nativeName: 'Hiri Motu' },
    hu: { name: 'Hungarian', nativeName: 'Magyar' },
    ia: { name: 'Interlingua', nativeName: 'Interlingua' },
    id: { name: 'Indonesian', nativeName: 'Bahasa Indonesia' },
    ie: { name: 'Interlingue', nativeName: 'Interlingue' },
    ga: { name: 'Irish', nativeName: 'Gaeilge' },
    ig: { name: 'Igbo', nativeName: 'Asụsụ Igbo' },
    ik: { name: 'Inupiaq', nativeName: 'Iñupiaq' },
    io: { name: 'Ido', nativeName: 'Ido' },
    is: { name: 'Icelandic', nativeName: 'Íslenska' },
    it: { name: 'Italian', nativeName: 'Italiano' },
    iu: { name: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
    ja: { name: 'Japanese', nativeName: '日本語' },
    jv: { name: 'Javanese', nativeName: 'Basa Jawa' },
    kl: { name: 'Kalaallisut, Greenlandic', nativeName: 'Kalaallisut' },
    kn: { name: 'Kannada', nativeName: 'ಕನ್ನಡ' },
    kr: { name: 'Kanuri', nativeName: 'Kanuri' },
    ks: { name: 'Kashmiri', nativeName: 'कश्मीरी, كشميري' },
    kk: { name: 'Kazakh', nativeName: 'Қазақ тілі' },
    km: { name: 'Khmer', nativeName: 'ភាសាខ្មែរ' },
    ki: { name: 'Kikuyu, Gikuyu', nativeName: 'Gĩkũyũ' },
    rw: { name: 'Kinyarwanda', nativeName: 'Ikinyarwanda' },
    ky: { name: 'Kirghiz, Kyrgyz', nativeName: 'кыргыз тили' },
    kv: { name: 'Komi', nativeName: 'коми кыв' },
    kg: { name: 'Kongo', nativeName: 'KiKongo' },
    ko: { name: 'Korean', nativeName: '한국어' },
    ku: { name: 'Kurdish', nativeName: 'Kurdî, كوردی' },
    kj: { name: 'Kwanyama, Kuanyama', nativeName: 'Kuanyama' },
    la: { name: 'Latin', nativeName: 'Latine' },
    lb: { name: 'Luxembourgish, Letzeburgesch', nativeName: 'Lëtzebuergesch' },
    lg: { name: 'Luganda', nativeName: 'Luganda' },
    li: { name: 'Limburgish, Limburgan, Limburger', nativeName: 'Limburgs' },
    ln: { name: 'Lingala', nativeName: 'Lingála' },
    lo: { name: 'Lao', nativeName: 'ພາສາລາວ' },
    lt: { name: 'Lithuanian', nativeName: 'Lietuvių kalba' },
    lu: { name: 'Luba-Katanga', nativeName: 'Luba-Katanga' },
    lv: { name: 'Latvian', nativeName: 'Latviešu valoda' },
    gv: { name: 'Manx', nativeName: 'Gaelg, Gailck' },
    mk: { name: 'Macedonian', nativeName: 'македонски јазик' },
    mg: { name: 'Malagasy', nativeName: 'Malagasy fiteny' },
    ms: { name: 'Malay', nativeName: 'Bahasa Melayu, بهاس ملايو' },
    ml: { name: 'Malayalam', nativeName: 'മലയാളം' },
    mt: { name: 'Maltese', nativeName: 'Malti' },
    mi: { name: 'Māori', nativeName: 'Te reo Māori' },
    mr: { name: 'Marathi (Marāṭhī)', nativeName: 'मराठी' },
    mh: { name: 'Marshallese', nativeName: 'Kajin M̧ajeļ' },
    mn: { name: 'Mongolian', nativeName: 'монгол' },
    na: { name: 'Nauru', nativeName: 'Ekakairũ Naoero' },
    nv: { name: 'Navajo, Navaho', nativeName: 'Diné bizaad, Dinékʼehǰí' },
    nb: { name: 'Norwegian Bokmål', nativeName: 'Norsk bokmål' },
    nd: { name: 'North Ndebele', nativeName: 'IsiNdebele' },
    ne: { name: 'Nepali', nativeName: 'नेपाली' },
    ng: { name: 'Ndonga', nativeName: 'Owambo' },
    nn: { name: 'Norwegian Nynorsk', nativeName: 'Norsk nynorsk' },
    no: { name: 'Norwegian', nativeName: 'Norsk' },
    ii: { name: 'Nuosu', nativeName: 'ꆈꌠ꒿ Nuosuhxop' },
    nr: { name: 'South Ndebele', nativeName: 'IsiNdebele' },
    oc: { name: 'Occitan', nativeName: 'Occitan' },
    oj: { name: 'Ojibwe, Ojibwa', nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
    cu: {
        name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
        nativeName: 'ѩзыкъ словѣньскъ'
    },
    om: { name: 'Oromo', nativeName: 'Afaan Oromoo' },
    or: { name: 'Oriya', nativeName: 'ଓଡ଼ିଆ' },
    os: { name: 'Ossetian, Ossetic', nativeName: 'ирон æвзаг' },
    pa: { name: 'Panjabi, Punjabi', nativeName: 'ਪੰਜਾਬੀ, پنجابی' },
    pi: { name: 'Pāli', nativeName: 'पाऴि' },
    fa: { name: 'Persian', nativeName: 'فارسی' },
    pl: { name: 'Polish', nativeName: 'Polski' },
    ps: { name: 'Pashto, Pushto', nativeName: 'پښتو' },
    pt: { name: 'Portuguese', nativeName: 'Português' },
    qu: { name: 'Quechua', nativeName: 'Runa Simi, Kichwa' },
    rm: { name: 'Romansh', nativeName: 'Rumantsch grischun' },
    rn: { name: 'Kirundi', nativeName: 'KiRundi' },
    ro: { name: 'Romanian, Moldavian, Moldovan', nativeName: 'română' },
    ru: { name: 'Russian', nativeName: 'русский язык' },
    sa: { name: 'Sanskrit (Saṁskṛta)', nativeName: 'संस्कृतम्' },
    sc: { name: 'Sardinian', nativeName: 'sardu' },
    sd: { name: 'Sindhi', nativeName: 'सिन्धी, سنڌي، سندھی' },
    se: { name: 'Northern Sami', nativeName: 'Davvisámegiella' },
    sm: { name: 'Samoan', nativeName: 'Gagana faa Samoa' },
    sg: { name: 'Sango', nativeName: 'yângâ tî sängö' },
    sr: { name: 'Serbian', nativeName: 'српски језик' },
    gd: { name: 'Scottish Gaelic; Gaelic', nativeName: 'Gàidhlig' },
    sn: { name: 'Shona', nativeName: 'ChiShona' },
    si: { name: 'Sinhala, Sinhalese', nativeName: 'සිංහල' },
    sk: { name: 'Slovak', nativeName: 'Slovenčina' },
    sl: { name: 'Slovene', nativeName: 'Slovenščina' },
    so: { name: 'Somali', nativeName: 'Soomaaliga, af Soomaali' },
    st: { name: 'Southern Sotho', nativeName: 'Sesotho' },
    es: { name: 'Spanish; Castilian', nativeName: 'Español' },
    su: { name: 'Sundanese', nativeName: 'Basa Sunda' },
    sw: { name: 'Swahili', nativeName: 'Kiswahili' },
    ss: { name: 'Swati', nativeName: 'SiSwati' },
    sv: { name: 'Swedish', nativeName: 'Svenska' },
    ta: { name: 'Tamil', nativeName: 'தமிழ்' },
    te: { name: 'Telugu', nativeName: 'తెలుగు' },
    tg: { name: 'Tajik', nativeName: 'тоҷикӣ, toğikī, تاجیکی' },
    th: { name: 'Thai', nativeName: 'ไทย' },
    ti: { name: 'Tigrinya', nativeName: 'ትግርኛ' },
    bo: { name: 'Tibetan Standard, Tibetan, Central', nativeName: 'བོད་ཡིག' },
    tk: { name: 'Turkmen', nativeName: 'Türkmen' },
    tl: { name: 'Tagalog', nativeName: 'Wikang Tagalog' },
    tn: { name: 'Tswana', nativeName: 'Setswana' },
    to: { name: 'Tonga (Tonga Islands)', nativeName: 'Faka Tonga' },
    tr: { name: 'Turkish', nativeName: 'Türkçe' },
    ts: { name: 'Tsonga', nativeName: 'Xitsonga' },
    tt: { name: 'Tatar', nativeName: 'татарча, tatarça, تاتارچا' },
    tw: { name: 'Twi', nativeName: 'Twi' },
    ty: { name: 'Tahitian', nativeName: 'Reo Tahiti' },
    ug: { name: 'Uighur, Uyghur', nativeName: 'Uyƣurqə, ئۇيغۇرچە' },
    uk: { name: 'Ukrainian', nativeName: 'українська' },
    ur: { name: 'Urdu', nativeName: 'اردو' },
    uz: { name: 'Uzbek', nativeName: 'Ўзбек, أۇزبېك' },
    ve: { name: 'Venda', nativeName: 'Tshivenḓa' },
    vi: { name: 'Vietnamese', nativeName: 'Tiếng Việt' },
    vo: { name: 'Volapük', nativeName: 'Volapük' },
    wa: { name: 'Walloon', nativeName: 'Walon' },
    cy: { name: 'Welsh', nativeName: 'Cymraeg' },
    wo: { name: 'Wolof', nativeName: 'Wollof' },
    fy: { name: 'Western Frisian', nativeName: 'Frysk' },
    xh: { name: 'Xhosa', nativeName: 'IsiXhosa' },
    yi: { name: 'Yiddish', nativeName: 'ייִדיש' },
    yo: { name: 'Yoruba', nativeName: 'Yorùbá' },
    za: { name: 'Zhuang, Chuang', nativeName: 'Saɯ cueŋƅ' }
};

export const backgroundPatternDots = {
    background: `radial-gradient(black 15%, transparent 16%) 0 0,
radial-gradient(black 15%, transparent 16%) 8px 8px,
radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px`,
    backgroundSize: '5px 5px'
};
