import { ProductCard } from '@components/ProductCard';
import Grid from '@mui/material/Grid2';
import { IconAccessible, IconRadar, IconReport } from '@tabler/icons-react';

function ProductCards() {
    const products = [
        {
            name: 'Assist',
            link: '/assist',
            icon: <IconAccessible size='2rem' />,
            learnMoreLink: 'EAassist'
        },
        {
            name: 'Audit',
            link: '/audit',
            icon: <IconRadar size='2rem' />,
            learnMoreLink: 'EAaudit'
        },
        {
            name: 'Report',
            link: '/report/overview',
            icon: <IconReport size='2rem' />,
            learnMoreLink: 'EAreport'
        }
    ];

    return (
        <Grid container spacing={4}>
            {products.map(product => (
                <Grid
                    key={product.name}
                    size={{
                        sm: 6,
                        xl: 4,
                        xs: 12
                    }}
                >
                    <ProductCard
                        icon={product.icon}
                        learnMoreLink={product.learnMoreLink}
                        link={product.link}
                        product={product.name}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default ProductCards;
