import { Button, Card, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function BlogCard({ image, title, description, to }) {
    const { t } = useTranslation();

    return (
        <Card
            sx={{
                p: 2.5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2.5,
                height: '100%'
            }}
        >
            <Typography component='h2' variant='h5'>
                {title}
            </Typography>
            <img
                aria-hidden='true'
                alt=''
                loading='eager'
                src={image}
                style={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    border: '1px solid #aaa',
                    borderRadius: '8px'
                }}
                title={title}
            />
            <Typography
                component='div'
                sx={{
                    fontWeight: 'light'
                }}
            >
                {description}
            </Typography>
            <Button
                aria-label={t('learnMoreAbout') + title}
                color='primary'
                size='small'
                sx={{
                    mt: 'auto'
                }}
                href={to}
                variant='outlined'
                width='100%'
            >
                {t('linkMoreLabel')}
            </Button>
        </Card>
    );
}

// Typechecking props for the BlogCard
BlogCard.propTypes = {
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
};

export default BlogCard;
