import {
    assistDemoModeAtom,
    auditDemoModeAtom,
    demoModeAtom,
    reportDemoModeAtom,
    userAtom,
    easyDemoModeAtom,
    translateDemoModeAtom
} from '@/global-store.jsx';
import NavBadge from '@/layout/NavBadge.jsx';
import useWindowSize from '@hooks/useWindowSize.jsx';
import {
    Box,
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconChevronDown, IconChevronUp, IconPoint } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import NavItem from './NavItem.jsx';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //
function NavCollapse({ menu, level }) {
    const [user] = useAtom(userAtom);

    const defaultExpanded = menu.handle?.defaultExpanded || false;

    const [open, setOpen] = useState(defaultExpanded);

    const [reportDemoMode] = useAtom(reportDemoModeAtom);
    const [assistDemoMode] = useAtom(assistDemoModeAtom);
    const [auditDemoMode] = useAtom(auditDemoModeAtom);
    const [easyDemoMode] = useAtom(easyDemoModeAtom);
    const [translateDemoMode] = useAtom(translateDemoModeAtom);
    const [generalDemoMode] = useAtom(demoModeAtom);

    const { t } = useTranslation();

    //const navigate = useNavigate();
    const { pathname } = useLocation();

    const navigate = useNavigate();

    const theme = useTheme();
    const windowsSize = useWindowSize().width;
    const isMobileOrTablet = windowsSize < theme.breakpoints.values.xl;

    const handleClick = () => {
        if (open) setOpen(false);
        else {
            setOpen(true);
            if (!isMobileOrTablet) {
                // if not mobile or tablet, navigate to first child automatically
                return navigate(menu.children[0].path);
            }
        }
    };

    const checkOpenForParent = children => {
        children.forEach(item => {
            // replace dynamic segments with regex
            if (!item.path) console.error('No path provided for route:', item);
            else if (pathname.includes(item.path.replace(/\/:[^]*/g, ''))) {
                setOpen(true);
            }
        });
    };

    // menu collapse for sub-levels
    useEffect(() => {
        if (!defaultExpanded) setOpen(false);

        if (menu.children) {
            menu.children.forEach(item => {
                // replace dynamic segments with regex
                if (pathname.includes(item.path.replace(/\/:[^]*/g, ''))) {
                    setOpen(true);
                } else if (item.children?.length) {
                    checkOpenForParent(item.children);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu.children]);

    const title = menu.handle.i18nNameKey
        ? t(menu.handle.i18nNameKey)
        : menu.handle.name || 'NO ROUTE NAME PROVIDED';

    // menu collapse & item
    const menus = menu.children?.map(item => {
        let allowed = item.handle?.allowed;
        if (allowed) {
            if (!Array.isArray(allowed)) {
                allowed = [allowed];
            }
            for (let allowedItem of allowed) {
                if (allowedItem === 'admin' && !user.isAdmin) return;
                if (allowedItem === 'salesAdmin' && !(user.isAdmin || user.isSalesAdmin)) return;
            }
        }
        if (item.handle?.type === 'hidden') return;

        return (
            <li key={item.path}>
                <NavItem item={item} level={level + 1} collapsibleTitle={title} />
            </li>
        );
    });

    let showBadge = false;
    if (menu.handle?.badge) {
        if (menu.handle.badge.showWhen === 'always') showBadge = true;
        else if (!generalDemoMode) {
            // dont show these badges in demo mode
            if (menu.handle.badge.showWhen === 'reportDemoMode' && reportDemoMode) showBadge = true;
            else if (menu.handle.badge.showWhen === 'auditDemoMode' && auditDemoMode)
                showBadge = true;
            else if (menu.handle.badge.showWhen === 'assistDemoMode' && assistDemoMode)
                showBadge = true;
            else if (menu.handle.badge.showWhen === 'translateDemoMode' && translateDemoMode) {
                showBadge = true;
            } else if (menu.handle.badge.showWhen === 'easyDemoMode' && easyDemoMode)
                showBadge = true;
        }
    }

    const Icon = menu.handle?.icon;

    const menuIcon = menu.handle?.icon ? (
        <Icon
            size='1.35rem'
            strokeWidth={1.5}
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
        />
    ) : (
        <IconPoint
            fontSize={level > 0 ? 'inherit' : 'medium'}
            sx={{
                width: 6,
                height: 6
            }}
        />
    );

    return (
        <>
            <Tooltip
                title={menu.handle.i18nTooltipKey ? t(menu.handle.i18nTooltipKey) : null}
                placement='right'
                describeChild
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 12]
                                }
                            }
                        ]
                    }
                }}
            >
                <ListItemButton
                    role='button'
                    aria-expanded={open ? 'true' : 'false'}
                    aria-controls={'nav-collapse-' + title.replaceAll(' ', '-')}
                    onClick={handleClick}
                    selected={open}
                    sx={[
                        theme => ({
                            'm': 0,
                            'mb': '4px',
                            'display': 'flex',
                            'alignItems': 'flex-start',
                            'padding': '9px 14px !important',
                            'borderRadius': theme.borders.borderRadius.xl,
                            'transition': 'none',
                            'lineHeight': '1 !important',

                            '& .MuiListItemIcon-root, & svg': {
                                color: '#fff !important'
                            },

                            '& .custom-svg-icon': {
                                width: '1.35rem',
                                height: '1.35rem'
                            },

                            '&:hover, &:focus': {
                                'outline': '2px solid white',
                                'outlineOffset': '-1px',
                                'backgroundColor': '#fff !important',
                                'color': '#000 !important',

                                '& .MuiListItemIcon-root, & svg': {
                                    color: '#000 !important'
                                }
                            }
                        }),
                        level > 1
                            ? {
                                  backgroundColor: 'transparent !important'
                              }
                            : {
                                  backgroundColor: 'inherit'
                              },
                        level > 1
                            ? {
                                  '&.Mui-selected': {
                                      backgroundColor: 'transparent !important'
                                  }
                              }
                            : {
                                  '&.Mui-selected': {
                                      backgroundColor: 'inherit'
                                  }
                              }
                    ]}
                >
                    <ListItemIcon sx={{ my: 'auto', minWidth: '36px' }} aria-hidden={true}>
                        {menuIcon}
                    </ListItemIcon>

                    <ListItemText
                        primary={
                            <Typography
                                color='inherit'
                                component='div'
                                variant='body1'
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    display: 'flex',
                                    fontWeight: 400,
                                    letterSpacing: '0.25px',
                                    gap: 1.5
                                }}
                            >
                                <Box
                                    sx={{
                                        flexGrow: 0
                                    }}
                                >
                                    {title}
                                </Box>

                                {showBadge ? (
                                    <NavBadge
                                        sx={{
                                            fontSize: '12px',
                                            mr: 1.25
                                        }}
                                        text={menu.handle.badge.text}
                                    />
                                ) : null}
                            </Typography>
                        }
                        secondary={
                            menu.handle?.i18nCaptionKey ? (
                                <Typography component='div' variant='subMenuCaption'>
                                    {t(menu.handle.i18nCaptionKey)}
                                </Typography>
                            ) : null
                        }
                        sx={{ m: 0 }}
                    />

                    {open ? (
                        <IconChevronUp
                            size='1.4rem'
                            stroke={2.5}
                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                        />
                    ) : (
                        <IconChevronDown
                            size='1.4rem'
                            stroke={2.5}
                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                        />
                    )}
                </ListItemButton>
            </Tooltip>
            <Collapse
                id={'nav-collapse-' + title.replaceAll(' ', '-')}
                in={open}
                timeout='auto'
                unmountOnExit
            >
                <List
                    component='ul'
                    disablePadding
                    sx={{
                        // left line
                        'position': 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: '25px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: 1,
                            background: '#fff'
                        }
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    );
}

NavCollapse.propTypes = {
    level: PropTypes.number,
    menu: PropTypes.object
};

export default NavCollapse;
