import borders from '@assets/theme/base/borders';
import typography from '@assets/theme/base/typography';
import pxToRem from '@assets/theme/functions/pxToRem';

const { fontWeightBold, size } = typography;
const { borderRadius } = borders;

const button = {
    defaultProps: {
        disableRipple: false,
        variant: 'outlined'
        //LinkComponent: RouterLink
    },
    styleOverrides: {
        root: {
            'display': 'inline-flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            'fontSize': pxToRem(12),
            'fontWeight': fontWeightBold,
            'borderRadius': borderRadius.lg,
            'lineHeight': 1.4,
            'textAlign': 'center',
            'textTransform': 'uppercase',
            'userSelect': 'none',
            'backgroundSize': '150% !important',
            'backgroundPositionX': '25% !important',
            'transition': 'all 150ms ease-in',
            'minHeight': pxToRem(41),
            'padding': `${pxToRem(10)} ${pxToRem(24)}`,

            '&:disabled': {
                pounterEvents: 'none',
                pointerEvent: 'none',
                opacity: 0.65,
                cursor: 'not-allowed !important'
            },

            '& .material-icons, & .tabler-icon': {
                fontSize: pxToRem(18) + '!important',
                width: pxToRem(18),
                height: pxToRem(18)
            },

            '&.MuiButton-sizeSmall': {
                'minHeight': pxToRem(32),
                'padding': `${pxToRem(6)} ${pxToRem(16)}`,
                'fontSize': size.xxs,

                '& .material-icons, & .tabler-icon': {
                    fontSize: pxToRem(15) + '!important',
                    width: pxToRem(15),
                    height: pxToRem(15)
                }
            },

            '&.MuiIconButton-sizeSmall': {
                '& .material-icons, & .tabler-icon': {
                    fontSize: pxToRem(15) + '!important',
                    width: pxToRem(15),
                    height: pxToRem(15)
                }
            },

            '&.MuiButton-sizeLarge': {
                'minHeight': pxToRem(51),
                'padding': `${pxToRem(12)} ${pxToRem(28)}`,
                'fontSize': pxToRem(14.25),

                '& .material-icons, & .tabler-icon': {
                    fontSize: pxToRem(24) + '!important',
                    width: pxToRem(24),
                    height: pxToRem(24)
                }
            },

            '&.MuiIconButton-sizeLarge': {
                '& .material-icons, & .tabler-icon': {
                    fontSize: pxToRem(24) + '!important',
                    width: pxToRem(24),
                    height: pxToRem(24)
                }
            }
        },
        contained: {
            '&:active, &:active:focus, &:active:hover': {
                opacity: 0.85
            }
        },
        outlined: {
            borderWidth: '2px !important'
        },
        outlinedSizeSmall: {
            borderWidth: '1.5px !important'
        },
        outlinedSizeLarge: {
            borderWidth: '2.5px !important'
        },
        text: {
            'boxShadow': 'none',

            '&:hover': {
                boxShadow: 'none'
            },

            '&:focus': {
                boxShadow: 'none'
            },

            '&:active, &:active:focus, &:active:hover': {
                boxShadow: 'none'
            },

            '&:disabled': {
                boxShadow: 'none',
                cursor: 'not-allowed !important'
            }
        }
    }
};

export default button;
