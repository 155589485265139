import ConformanceTargetSelect from '@/pages/report/reportComponents/ConformanceTargetSelect';
import GlobalDomainSelect from '@/pages/report/reportComponents/GlobalDomainSelect';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

function PageTitle({
    title = 'No Title',
    showDomainSelect = false,
    showConformanceTarget = false
}) {
    return (
        <Grid
            container
            sx={{
                alignItems: 'center',
                gap: 2,
                justifyContent: 'space-between'
            }}
        >
            <Box
                component='h1'
                sx={{
                    fontSize: '1.65rem',
                    fontWeight: 600
                }}
            >
                {title}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: 1.35,
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                }}
            >
                {showDomainSelect && <GlobalDomainSelect />}
                {showConformanceTarget && <ConformanceTargetSelect small />}
            </Box>
        </Grid>
    );
}

export default PageTitle;
