const tabs = {
    styleOverrides: {
        root: {
            position: 'relative',
            borderRadius: 0,
            minHeight: 'unset',
            padding: 0,
            backgroundColor: 'transparent'
        },

        flexContainer: {
            height: '100%',
            position: 'relative',
            zIndex: 10,
            padding: '4px',
            paddingBottom: 0
        },

        fixed: {
            overflow: 'unset !important',
            overflowX: 'unset !important'
        },

        vertical: {
            '& .MuiTabs-indicator': {
                width: '100%'
            }
        },

        indicator: {
            display: 'none'
            /* display: 'flex',
            justifyContent: 'center',
            color: '#fff !important',
            backgroundColor: '#fff !important',
            height: 6 */
        }
    },

    defaultProps: {
        TabIndicatorProps: {
            children: (
                <span
                    className='MuiTabs-indicatorSpan'
                    style={{
                        display: 'none',
                        maxWidth: '60px',
                        width: '100%',
                        backgroundColor: '#222'
                    }}
                />
            )
        }
    }
};

export default tabs;
