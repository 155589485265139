import { userAtom } from '@/global-store.jsx';
import typography from '@assets/theme/base/typography.jsx';
import RouterLink from '@components/RouterLink.jsx';
import { Box } from '@mui/material';
import { IconHeartFilled } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

function Footer({ white, forceColumnLayout, sx }) {
    const [user] = useAtom(userAtom);
    const { t } = useTranslation();
    const { size } = typography;
    const links = [
        {
            href: 'EAmission',
            name: t('footerUs')
        },
        { href: 'EAblog', name: t('footerBlog') },
        { href: '/privacy-policy', name: t('footerDataPrivacy'), external: !user }
    ];

    return (
        <Box
            sx={[
                {
                    alignItems: 'center',
                    color: white ? '#fff' : '#000',
                    display: 'flex',
                    flexDirection: { xs: 'column', xl: forceColumnLayout ? 'column' : 'row' },
                    fontSize: size.md,
                    gap: 1.5,
                    justifyContent: 'space-between',
                    mt: 'auto',
                    mb: 0,
                    pt: 5,
                    px: 2,
                    width: '100%',
                    pb: { xs: 2, sm: 0 }
                },
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
        >
            <div style={{ textAlign: 'center' }}>
                &copy; {new Date().getFullYear()}, made with
                <Box
                    component='span'
                    sx={{
                        mx: 0.38
                    }}
                >
                    <IconHeartFilled size='0.83rem' stroke={2} />
                </Box>
                by{' '}
                <RouterLink color='inherit' fontWeight='500' to='https://eye-able.com'>
                    Web Inclusion GmbH
                </RouterLink>{' '}
                for a better web.
            </div>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    m: 0,
                    p: 0,
                    gap: 2
                }}
            >
                {links.map(link => (
                    <RouterLink
                        to={link.href}
                        key={link.href}
                        color='inherit'
                        external={link.external ?? true}
                    >
                        {link.name}
                    </RouterLink>
                ))}
            </Box>
        </Box>
    );
}

export default Footer;
